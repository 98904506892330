.load-date {
    margin: 8px;
    border-radius: 10px;
}

.load-date p {
    font-weight: 600;
}

.load-date .load-date-content {
    padding: 5px 0;
    width: 100%;
}

.load-date .load-date-header {
    font-size: medium;
    font-weight: bold;
    margin: 4px;
}

.load-date .load-date-flavor {
    font-size: small;
    color: #212121;
    background-color: #E5F4E0;
    padding: 4px 8px;
    margin: 0;
    border-radius: 16px;
}

.load-date.open, .load-date.open .item {
    --ion-color-base: #E5F4E0 !important;
    --ion-color-contrast: transparent !important;
}

.load-date .load-date-sub {
    font-size: small;
    font-weight: bold;
    margin: 4px;
}

.top-icon {
    align-self: baseline;
    margin-top: 40px;
}

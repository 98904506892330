.sidenav {
    position: fixed;
    width: 0;
    height: 0;
    background: transparent;
    z-index: 10;
    overflow: hidden;
    transition: background .3s ease;
}

.sidenav.sidenav-open {
    display: block;
    width: 85vw;
    height: 100vh;
    background: #212121;
    box-shadow: 2px 2px 10px #212121;
}

.sidenav-content {
    width: 85vw;
    background: #212121;
    position: absolute;
    color: white;
    margin-left: calc(min(400px, 100vw) * -1);
    top: 0px;
    padding-top: 20px;
    bottom: 0px;
    transition: margin-left .3s ease;
    z-index: 11;
    overflow: auto;
}

.sidenav.sidenav-open .sidenav-content {
    margin-left: 0;
}

.sidenav-icon {
    width: 30px;
}

.sidenav-link {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 1.2rem;
    padding: 5px 5px 5px 20px;
    color: white;
}

.sidenav-link.active {
    color: #99cc00;
    text-decoration: none;
}

.sidenav-sublink {
    font-size: 0.9rem;
    margin-bottom: 15px;
    margin-left: 54px;
    display: grid;
    grid-template-columns: 80% 20%;
}
